<template>
  <v-app>
    <div
      class="loader-area"
      v-if="loading"
    >
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="5"
      />
    </div>
    <my-toast />
    <navigation-drawer
      v-if="isAuthenticated"
      :drawer="drawer"
      @onUpdateDrawer="(v) => (drawer = v)"
    />
    <v-system-bar
      v-if="isAuthenticated"
      color="deep-purple darken-3"
    />
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
      color=""
      v-if="isAuthenticated"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        class="d-none d-md-flex"
        v-text="title"
      />
      <v-spacer />
      <search-bar />
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="switchTheme"
            v-on="on"
          >
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.theme.dark ? 'Turn on light' : 'Turn off light' }}</span>
      </v-tooltip>
      <v-btn
        v-if="isAuthenticated"
        text
        @click="handleLogout"
      >
        <v-icon>mdi-logout</v-icon>
        <span class="d-none d-md-flex">Logout</span>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-snackbar
          bottom
          middle
          :value="updateExists"
          :timeout="-1"
        >
          An update is available
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              v-bind="attrs"
              @click="refreshApp"
            >
              Update
            </v-btn>
          </template>
        </v-snackbar>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationDrawer from "./components/common/NavigationDrawer.vue";
import loadInitialData from "./utils/initial-data";
import MyToast from "./components/common/MyToast.vue";
import SearchBar from "./components/common/SearchBar.vue";
import SWUpdateMixin from './utils/sw-update'


export default {
  name: "App",
  components: { NavigationDrawer, MyToast, SearchBar },
  mixins: [SWUpdateMixin],
  computed: {
    ...mapGetters(["isAuthenticated", "notificationCount"]),
  },
  data: () => ({
    clipped: false,
    drawer: true,
    title: "AmarDiary",
    loading: false,
    isDarkTheme: JSON.parse(localStorage.getItem('IS_DARK_THEME') || 'false') || false,
  }),
  async mounted() {
    this.$vuetify.theme.dark = this.isDarkTheme
    // Event Bus Handler Here
    this.$ebus.$on("GLOBAL_LOADER_ON", () => {
      this.loading = true
    });
    this.$ebus.$on("GLOBAL_LOADER_OFF", () => {
      this.loading = false
    });
    this.$ebus.$on("TRIGGERED_LOGOUT", () => {
      console.log("Logout due to security purpose.");
      this.$router.push("/login");
    });
    // Loading Initial Data
    this.loading = true
    await loadInitialData(this.$store);
    this.loading = false
    this.$ebus.$on("LOGIN_SUCCESSFULL", () => {
      // Do something you really need after just login
    });
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("triggerLogout");
    },
    switchTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('IS_DARK_THEME', JSON.stringify(this.$vuetify.theme.dark))
    },
  },
};
</script>

<style lang="scss">
.v-main {
  margin-top: -24px;
}
.loader-area {
  background-color: rgb(172 172 172 / 50%);
  width: 100%;
  height: 100%;
  z-index: 10000000;
  position: absolute;

  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 40%;
    z-index: 1000;
  }
}
</style>
