import { createOrUpdate, _get } from "../../utils/api-request";


const state = {
  invitationRequests: {}
}

const mutations = {
  setInvitationRequest(state, requests) {
    state.invitationRequests = requests
  },
  appendInvitationRequest(state, requests) {
    console.log(requests)
    state.invitationRequests.data.concat(requests.data)
    state.invitationRequests.pagination = requests.pagination
  },
  removeInvitationRequest(state, id) {
    const _index = state.invitationRequests.data.findIndex(i => i.id === id)
    state.invitationRequests.data.splice(_index, 1)
  }
}

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async requestForInvitationAction({ }, payload) {
    try {
      const response = await createOrUpdate(
        {
          path: "v1/invitations/requests",
          data: payload,
        }
      )
      if (response && response.status === 200) {
        return response.data
      } else {
        throw response
      }

    } catch (e) {
      console.log(e)
      return e.response
    }
  },
  async fetchInvitationRequestsAction({ state, commit }, payload) {
    try {
      let _path = "v1/invitations/requests"
      if (payload.loadMore) {
        _path = `v1/invitations/requests${state.invitationRequests.pagination.next}`
      }
      const response = await _get(
        {
          path: _path
        }
      )
      if (response && response.status === 200) {
        if (!payload.loadMore) {
          commit("setInvitationRequest", response.data)
        } else {
          commit("appendInvitationRequest", response.data)
        }
        return response.data
      } else {
        throw response
      }

    } catch (e) {
      console.log(e.response)
      return e.response
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async sendInvitationAction({ }, payload) {
    try {
      const response = await createOrUpdate(
        {
          path: `v1/invitations`,
          data: payload,
          method: "post"
        }
      )
      if (response && response.status === 201) {
        return response.data
      } else {
        throw response
      }

    } catch (e) {
      console.log(e)
      return e.response.data
    }
  },
  async updateRequestInvitationStatusAction({ commit }, payload) {
    try {
      const response = await createOrUpdate(
        {
          path: `v1/invitations/requests/${payload.id}`,
          data: payload,
          method: "patch"
        }
      )
      if (response && response.status === 200) {
        commit("removeInvitationRequest", payload.id)
        return response.data
      } else {
        throw response
      }

    } catch (e) {
      console.log(e)
      return e.response.data
    }
  },
}

const getters = {
  allInvitationRequests(state) {
    return state.invitationRequests.data || []
  },
  pendingInvitationPagination(state) {
    return state.invitationRequests.pagination || {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}