<template>
  <div>
    <v-snackbar
      v-for="(msg, _i) in messages"
      :style="{'margin-bottom':calcMargin(_i)}"
      :absolute="false"
      :key="_i"
      v-model="msg.show"
      :timeout="3000"
      :right="true"
      :color="color"
    >
      {{ msg.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="msg.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "MyToast",
  data() {
    return {
      color: "",
      show: false,
      messages: [],
    };
  },
  mounted() {
    this.$ebus.$on("newToastMessage", (payload) => {
      this.color = payload.color ? payload.color : "";
      this.messages.push({msg: payload.message, show: true})
        ? payload.message
        : "A new message has arrived.";
    });
  },
  methods: {
    calcMargin(i) {
      const hiddenMessages = this.messages.filter(i => !i.show)
      let hiddenCount = 0
      if (hiddenMessages && hiddenMessages.length > 0) {
        hiddenCount = hiddenMessages.length
      }
      return ((i - hiddenCount)*60) + 'px'
    },
  }
};
</script>
