<template>
  <div
    class="search-result-window"
    v-if="show && query !== ''"
  >
    <v-card :loading="loading">
      <v-card-actions>
        <v-btn
          icon
          @click="closeInfoWindow"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-title>
        Results on: {{ query }}
      </v-card-title>
      <v-card-text>
        <div
          v-for="value, key in result"
          :key="key"
        >
          <div v-if="value.length !== 0">
            <p class="text-subtitle-1">
              {{ searchKey(key) }}
            </p>
            <v-divider />
            <v-list-item
              v-for="data in value"
              :key="data.id"
              :to="navigateToResultUrl(key, data)"
              @click="closeInfoWindow"
            >
              <v-list-item-avatar>
                <v-icon>mdi-home</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SearchResultInfoWindow",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: ""
    },
    result: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      show: false,
      searchDataKeyMap: {
        keep_notes: "Notes",
        keep_labels: "Notes: Labels",
        money_wallets: "Money: Wallets",
        money_categories: "Money: Categories",
        money_transactions: "Money: Transactions",
      }
    }
  },
  methods: {
    closeInfoWindow () {
      this.show = false
      this.$emit("closeInfoWindow")
    },
    navigateToResultUrl (key, data) {
      if (key === "keep_notes") {
        let _path = '/notes/all'
        if (data.status === 'archived') {
          _path = '/notes/archive'
        }
        else if (data.status === 'deleted') {
          _path = '/notes/trash'
        }
        return {path: _path, query: {noteId: data.id}}
      } else if (key === "keep_labels") {
        return {path: `/notes/${data.id}`}
      } else if (key === "money_wallets") {
        return {path: `/money/wallets`, query: {walletId: data.id}}
      } else if (key === "money_categories") {
        return {path: `/money/categories`, query: {categoryId: data.id}}
      } else if (key === "money_transactions") {
        return {path: `/money/transactions`, query: {transactionId: data.id}}
      }
    },
    searchKey (value) {
      const data = this.searchDataKeyMap[value]
      return data ? data : value
    }
  },
  watch: {
    focus (newValue) {
      this.show = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result-window {
  width: 49%;
  position: absolute;
  left: 24.8%;
  top: 53px;
  z-index: 10000;

  .v-card {
    max-height: 350px;
    overflow: auto;
    border-radius: 2px;

    .v-card__actions {
      display: block;
      float: right;
    }
  }
}

@media (max-width: 960px) and (min-width: 561px) {
  .search-result-window {
    left: 21.5%
  }
}

@media (max-width: 562px) {
  .search-result-window {
    left: 0;
    width: 100%;
    padding: 0 5% 0 5%;
  }
}
</style>