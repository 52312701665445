<template>
  <div class="dashboard">
    <dashboard-report />
  </div>
</template>


<script>
import DashboardReport from '../components/reporting/dashboard/DashboardReport.vue'
export default {
  components: { DashboardReport },
  name: 'Home'
}
</script>

<style scoped>
  .dashboard {
    margin-left: 5px;
    margin-right: 5px;
  }
</style>