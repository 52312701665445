import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import EventBus from '@/utils/event-bus'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import "./assets/main.scss"

// Cron Tab Editor
import cronCore from '@vue-js-cron/core'
import '@vue-js-cron/vuetify/dist/vuetify.css'

Vue.config.productionTip = false

Vue.prototype.$ebus = EventBus
Vue.use(DatetimePicker)
Vue.use(cronCore)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
