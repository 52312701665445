import { _get, createOrUpdate, _del } from "../../utils/api-request";

const state = {
  notes: [],
  notePagination: {},
  archivedNotes: [],
  archivedNotePagination: {},
  deletedNotes: [],
  labels: []
}

const mutations = {
  addNewNote(state, note) {
    if (note.status === "deleted") {
      state.deletedNotes.push(note)
    } else if (note.status === "archived") {
      state.archivedNotes.push(note)
    } else {
      state.notes.push(note)
    }
  },
  setNote(state, noteResponse) {
    state.notes = noteResponse.data
    state.notePagination = noteResponse.pagination
  },
  appendNotes(state, noteResponse) {
    state.notePagination = noteResponse.pagination
    state.notes = [...state.notes, ...noteResponse.data]
  },
  noteRestoreFromArchive(state, data) {
    const noteIndex = state.archivedNotes.findIndex((item) => {
      return item.id == data.noteId
    })
    if (noteIndex !== -1) {
      state.archivedNotes.splice(noteIndex, 1)
    }
    state.notes.push(data.note)
  },
  noteRestoreFromTrash(state, data) {
    const noteIndex = state.deletedNotes.findIndex((item) => {
      return item.id == data.noteId
    })
    if (noteIndex !== -1) {
      state.deletedNotes.splice(noteIndex, 1)
    }
    state.notes.push(data.note)
  },
  setArchivedNote(state, notes) {
    state.archivedNotes = notes.data
    state.archivedNotePagination = notes.pagination
  },
  appendArchivedNote(state, notes) {
    state.archivedNotes.push(...notes.data)
    state.archivedNotePagination = notes.pagination
  },
  setDeletedNote(state, notes) {
    state.deletedNotes = notes
  },
  addNewLabel(state, label) {
    state.labels.push(label)
  },
  removeDeletedNote(state, payload) {
    const _index = state[payload.deleteFrom].findIndex(i => i.id === payload.noteId)
    if (_index !== -1) {
      state[payload.deleteFrom].splice(_index, 1)
    }
  },
  updateExistingLabel(state, label) {
    const _index = state.labels.findIndex((item) => {
      return item.id === label.id
    })
    state.labels.splice(_index, 1, label)
  },
  removeExistingLabel(state, labelId) {
    const _index = state.labels.findIndex((item) => {
      return item.id === labelId
    })
    state.labels.splice(_index, 1)
  },
  setLabel(state, labels) {
    state.labels = labels
  },
  updateNoteCoverPhoto(state, payload) {
    const noteIndex = state.notes.findIndex((item) => {
      return item.id === payload.noteId
    })
    state.notes[noteIndex].cover_photo = payload.relative_path
  },
  updateNoteCheckboxItem(state, payload) {
    const noteIndex = state.notes.findIndex((item) => {
      return item.id === payload.noteId
    })
    const note = state.notes[noteIndex]
    for (let i = 0; i < note.note_list.length; i++) {
      const item = note.note_list[i];
      if (item.id === payload.checkItem.id) {
        note.note_list[i] = {
          ...item, is_done: !item.is_done
        }
      }
    }
    state.notes[noteIndex] = note
  },
  updateExistingNote(state, note) {
    const noteIndex = state.notes.findIndex((item) => {
      return item.id === note.id
    })
    state.notes.splice(noteIndex, 1, note)
  },
  removeExistingNote(state, data) {
    const noteIndex = state.notes.findIndex((item) => {
      return item.id === data.noteId
    })
    state.notes.splice(noteIndex, 1)
    if (data.status === "archived") {
      state.archivedNotes.push({ ...data.note, status: data.status })
    }
    else if (data.status === "deleted") {
      state.deletedNotes.push({ ...data.note, status: data.status })
    }
  }
}

const actions = {
  addNewNoteAction({ commit }, note) {
    commit("addNewNote", note)
  },
  async getAllLabelsFromAPI({ commit }) {
    try {
      const response = await _get({ path: "v1/keep/labels" })
      if (response.status === 200) {
        commit('setLabel', response.data.data)
        return response
      }
    } catch (e) {
      return e
    }
  },
  async getAllNotesFromAPI({ state, commit }) {
    try {
      const path = state.notePagination.next ? `v1/keep/notes${state.notePagination.next}` : "v1/keep/notes"
      const response = await _get({ path: path })
      if (response.status === 200) {
        if (state.notePagination.next) {
          commit('appendNotes', response.data)
        } else {
          commit('setNote', response.data)
        }
        return response
      }
    } catch (e) {
      return e
    }
  },
  async getSingleNoteFromAPI({ commit }, noteId) {
    try {
      const response = await _get({ path: `v1/keep/notes/${noteId}` })
      if (response.status === 200) {
        commit('addNewNote', response.data.data)
        return response.data
      }
    } catch (e) {
      return e.response.data
    }
  },
  async getStatusWiseNotesFromAPI({ state, commit }, status) {
    try {
      const path = state.archivedNotePagination.next ? `v1/keep/notes${state.archivedNotePagination.next}` : `v1/keep/notes?note_status=${status}`
      const response = await _get({ path: path })
      if (response.status === 200) {
        if (status === "archived") {
          if (state.archivedNotePagination.next) {
            commit('appendArchivedNote', response.data)
          }
          else {
            commit('setArchivedNote', response.data)
          }
        } else if (status === "deleted") {
          commit('setDeletedNote', response.data.data)
        }
        return response
      }
    } catch (e) {
      return e
    }
  },
  async updateCheckboxItem({ commit }, payload) {
    try {
      // API call first and then update to local
      const response = await createOrUpdate(
        {
          path: `v1/keep/notes/${payload.noteId}/update-checkbox-item`,
          data: { ...payload.checkItem },
          method: "patch"
        }
      )
      if (response && response.data.status === 200) {
        commit("updateNoteCheckboxItem", payload)
      }
      return response.data
    } catch (e) {
      throw e.response
    }
  },
  async updateNoteCoverImage({ commit }, payload) {
    try {
      const response = await createOrUpdate(
        {
          path: `v1/keep/notes/${payload.noteId}/update-cover-image`,
          data: { "cover_photo": payload.relative_path },
          method: "patch"
        }
      )
      if (response && response.status === 200) {
        commit("updateNoteCoverPhoto", payload)
        return response.data
      }
    } catch (e) {
      return e
    }
  },
  async createUpdateNoteAction({ commit }, payload) {
    try {
      // API call first and then update to local
      const url = payload.method === 'put' ? `v1/keep/notes/${payload.id}` : "v1/keep/notes"
      const response = await createOrUpdate(
        {
          path: url,
          data: { ...payload.data },
          method: payload.method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewNote", response.data.data)
      }
      if (response && response.data.status === 200) {
        commit("updateExistingNote", response.data.data)
      }
      return response.data
    } catch (e) {
      throw e.response
    }
  },
  async createNewLabelAction({ commit }, payload) {
    try {
      const path = payload.id ? `v1/keep/labels/${payload.id}` : 'v1/keep/labels'
      const method = payload.id ? 'put' : 'post'
      const response = await createOrUpdate(
        {
          path: path,
          data: payload,
          method: method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewLabel", response.data.data)
      }
      return response.data
    } catch (e) {
      throw e.response
    }
  },
  async updateNoteStatusAction({ commit }, payload) {
    try {
      // API call first and then update to local
      const url = `v1/keep/notes/${payload.noteId}/update-status`
      const response = await createOrUpdate(
        {
          path: url,
          data: { status: payload.status },
          method: "patch"
        }
      )
      if (response && response.data.status === 200) {
        if (payload.status === "active") {
          if (payload.note.status === "archived") {
            commit("noteRestoreFromArchive", payload)
          } else {
            commit("noteRestoreFromTrash", payload)
          }
        } else {
          commit("removeExistingNote", payload)
        }
      }
      return response.data
    } catch (e) {
      throw e.response
    }
  },
  async deleteNoteAction({ commit }, payload) {
    try {
      // API call first and then update to local
      const response = await _del({ path: `v1/keep/notes/${payload.noteId}` })
      if (response && response.data && response.data.status === 206) {
        let payloadData = { ...payload }
        if (payload.status === "archived") {
          payloadData.deleteFrom = "archivedNotes"
        }
        else if (payload.status === "deleted") {
          payloadData.deleteFrom = "deletedNotes"
        }
        commit("removeDeletedNote", payloadData)
      }
      return response.data
    } catch (e) {
      return e.response.data
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async noteEmailSendAction({ }, payload) {
    try {
      const url = `v1/keep/notes/${payload.noteId}/send-email`
      const response = await createOrUpdate(
        {
          path: url,
          data: { ...payload.data },
          method: "post"
        }
      )
      return response.data
    } catch (e) {
      throw e.response
    }
  },
  async labelCreateOrUpdateAction({ commit }, payload) {
    try {
      const url = payload.id ? `v1/keep/labels/${payload.id}` : "v1/keep/labels"
      const method = payload.id ? "put" : "post"
      const response = await createOrUpdate(
        {
          path: url,
          data: { ...payload },
          method: method
        }
      )
      if (response.data.status === 200) {
        commit("updateExistingLabel", response.data.data)
        return response.data
      }
      if (response.data.status === 201) {
        commit("addNewLabel", response.data.data)
        return response.data
      }
    } catch (e) {
      console.log(e)
      return e.response
    }
  },
  async deleteLabelAction({ commit }, label) {
    const response = await _del({ path: `v1/keep/labels/${label.id}` })
    if (response.data.status === 206) {
      commit("removeExistingLabel", label.id)
      return response.data
    }
  }
}

const getters = {
  allNoteList(state) {
    return [...new Map(state.notes.map(v => [v.id, v])).values()]
  },
  notePagination(state) {
    return state.notePagination
  },
  archivedNoteList(state) {
    return state.archivedNotes
  },
  archivedNotePagination(state) {
    return state.archivedNotePagination
  },
  deletedNoteList(state) {
    return state.deletedNotes
  },
  allLabelList(state) {
    return state.labels
  },
  labelsNavItems(state) {
    const labelNav = {
      subLinks: [
        {
          icon: "mdi-label-multiple-outline",
          title: "All Labels",
          identifier: "all_labels",
          to: "/labels",
        }
      ],
    }
    const _navs = state.labels.map((item) => {
      return {
        icon: "mdi-label-variant",
        title: item.name,
        identifier: item.name,
        to: `/${item.id}`,
      }
    })
    labelNav["subLinks"].push(..._navs)
    return labelNav
  },
  labelWiseNotes(state) {
    const _notes = { all: [] }
    for (let i = 0; i < state.notes.length; i++) {
      const item = state.notes[i]
      if (item.label && !(item.label.id in _notes)) {
        _notes[item.label.id] = [item]
        _notes.all.push(item)
      } else if (item.label && item.label.id in _notes) {
        _notes[item.label.id].push(item)
        _notes.all.push(item)
      }
    }
    return _notes
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}