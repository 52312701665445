<template>
  <div>
    <v-row dense>
      <v-col
        cols="6"
        md="4"
        style="margin: 0 auto;"
      >
        <transaction-pie-chart-report
          v-if="trxPieChartConfig.data.length > 0"
          :labels="trxPieChartConfig.labels"
          :background-colors="trxPieChartConfig.colors"
          :data="trxPieChartConfig.data"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <!-- Monthly Report -->
        <transaction-category-wise-report
          :labels="categoryMonthlyBarConfig.labels"
          :dataset="categoryMonthlyBarConfig.dataset"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <!-- Daily Report -->
        <transaction-category-wise-report
          :labels="categoryDailyBarConfig.labels"
          :dataset="categoryDailyBarConfig.dataset"
          report-type="Daily"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        style="margin: 0 auto;"
      >
        <wallet-pie-chart-report 
          v-if="walletPieChartConfig.data.length > 0"
          :labels="walletPieChartConfig.labels"
          :background-colors="walletPieChartConfig.colors"
          :data="walletPieChartConfig.data"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TransactionCategoryWiseReport from './TransactionCategoryWiseReport.vue'
import TransactionPieChartReport from './TransactionPieChartReport.vue'
import WalletPieChartReport from './WalletPieChartReport.vue'
import { _get } from "@/utils/api-request"
import { mapGetters } from 'vuex'

export default {
  components: { TransactionPieChartReport, TransactionCategoryWiseReport, WalletPieChartReport },
  name: "DashboardReport",
  data () {
    return {
      trxPieChartConfig: {
        colors: ["#32d4e3", "#e864a6", "#e6a61c"],
        data: [],
        labels: ["Monthly Income", "Monthly Expense", "Today's Expense"]
      },
      walletPieChartConfig: {
        colors: [],
        data: [],
        labels: []
      },
      categoryMonthlyBarConfig: {
        labels: [], // categories here
        dataset: [
          {
            label: 'Monthly Category wise data',
            backgroundColor: '#f87979',
            data: []
          },
        ]
      },
      categoryDailyBarConfig: {
        labels: [], // categories here
        dataset: [
          {
            label: 'Daily Category wise data',
            backgroundColor: '#1e9c61',
            data: []
          },
        ]
      }
    }
  },
  computed: {
    ...mapGetters(["wallets"])
  },
  async mounted () {
    this.getTransactionSummaryData()
    this.getMonthlyCategoryData()
    this.getDailyCategoryData()
    await this.$store.dispatch("getAllWalletsFromAPI")
    this.generateWalletPieChartData()
  },
  methods: {
    async getTransactionSummaryData() {
      try {
        const response = await _get({path: 'v1/reporting/transaction-summary'})
        if (response.status === 200) {
          const tmp = response.data.data
          const _data = []
          _data.push(tmp["monthly_income"])
          _data.push(tmp["monthly_expense"])
          _data.push(tmp["todays_expense"])
          this.trxPieChartConfig.data = _data
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    async getMonthlyCategoryData() {
      try {
        const response = await _get({path: 'v1/reporting/monthly-category-wise-expense'})
        if (response.status === 200) {
          const tmp = response.data.data
          const _data = []
          tmp.map((data) => {
            this.categoryMonthlyBarConfig.labels.push(data.category_name)
            _data.push(data.total_amount)
          })
          this.categoryMonthlyBarConfig.dataset[0].data = _data
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    async getDailyCategoryData() {
      try {
        const response = await _get({path: 'v1/reporting/daily-category-wise-expense'})
        if (response.status === 200) {
          const tmp = response.data.data
          const _data = []
          tmp.map((data) => {
            this.categoryDailyBarConfig.labels.push(data.category_name)
            _data.push(data.total_amount)
          })
          this.categoryDailyBarConfig.dataset[0].data = _data
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    generateWalletPieChartData() {
      // Ignoring the credit wallets (i.e: Credit Cards)
      this.wallets.filter(i => i.type !== 'credit').map((wal) => {
        this.walletPieChartConfig.labels.push(wal.name)
        this.walletPieChartConfig.data.push(wal.current_amount)
        this.walletPieChartConfig.colors.push(`#${Math.floor(Math.random()*16777215).toString(16)}`)
      })
    }
  }
}
</script>

<style scoped>
.row:nth-child(2) {
  margin-top: 4rem;
}
</style>