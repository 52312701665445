import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'
import middlewarePipeline from '../middlewares/middleware-pipeline'
import predefinedMiddlewares from '../middlewares/predefined-middlewares'
import eventBus from '../utils/event-bus'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  eventBus.$emit("GLOBAL_LOADER_ON")
  let middlewares = to.meta.middleware || []
  middlewares = [...predefinedMiddlewares.getMiddlewareBundle(), ...middlewares]

  const context = {
    to,
    from,
    next,
    store
  }

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1)
  })
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Ashraful's Space"
    setTimeout(() => {
      eventBus.$emit("GLOBAL_LOADER_OFF")
    }, 300)
  });
});

export default router