<template>
  <div class="searchbar">
    <v-text-field
      dense
      append-icon="mdi-magnify"
      label="Search"
      @change="onTextUpdate"
      @focus="onFocus = true"
      placeholder="Write here and Press ENTER"
      outlined
      :disabled="loading"
    />
    <search-result-info-window
      :query="queryText"
      :loading="loading"
      :focus="onFocus"
      :result="resultData"
      @closeInfoWindow="onFocus = false"
    />
  </div>
</template>

<script>
import { _get } from '../../utils/api-request'
import SearchResultInfoWindow from './SearchResultInfoWindow.vue'
export default {
  components: { SearchResultInfoWindow },
  name: "SearchBar",
  data () {
    return {
      queryText: "",
      onFocus: false,
      resultData: {},
      loading: false
    }
  },
  methods: {
    async callSearchAPI () {
      try {
        this.resultData = {}
        this.loading = true
        const response =  await _get({path: `v1/search?search_text=${this.queryText}`})
        if (response && response.status === 200) {
          this.resultData = response.data.data
          this.loading = false
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    onTextUpdate (value) {
      this.queryText = value
    }
  },
  watch: {
    queryText () {
      this.callSearchAPI()
    }
  }
}
</script>

<style scoped>
.searchbar {
  margin-top: 25px;
  width: 50%;
}
</style>