export default {
  request({ next }) {
    // This will call in every request at first
    next()
  },
  getMiddlewareBundle () {
    return [
      this.request
    ]
  }
}