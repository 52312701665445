import { createOrUpdate, _get } from '@/utils/api-request'
import EventBus from "@/utils/event-bus";

const state = {
    notifications: [],
    unreadNotifications: [],
}

const mutations = {
  addNewNotification(state, payload) {
    state.notifications.unshift(payload)
  },
  addNewUnreadNotificationAction(state, payload) {
    state.unreadNotifications.unshift(payload)
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setUnreadNotifications(state, notifications) {
    state.unreadNotifications = notifications
  },
  removeUnreadNotifications(state, id) {
    const index = state.unreadNotifications.findIndex(i => i.id === id)
    if (index != -1) {
      state.unreadNotifications.splice(index, 1)
    }
  }
}

const actions = {
  addNewUnreadNotificationAction ({commit}, payload) {
    commit("addNewNotification", payload)
  },
  // eslint-disable-next-line no-empty-pattern
  async createNotificationDevice({  }, token) {
    try {
      const response = await createOrUpdate({ path: "v1/notifications/device", data: {token: token} })
      if (response && response.status === 201) {
        EventBus.$emit("newToastMessage", {
          message: "All set! Now we can notify you. :)"
        })
      }
      return response.data
    } catch (e) {
      return e
    }
  },
  async getAllNotifications({ commit }) {
    try {
      const response = await _get({ path: "v1/notifications"})
      if (response.status === 200) {
        commit("setNotifications", response.data.data)
        return response.data
      }
    } catch (e) {
      return e
    }
  },
  async getUnreadNotifications({ commit }) {
    try {
      const response = await _get({ path: "v1/notifications/unread"})
      if (response.status === 200) {
        commit("setUnreadNotifications", response.data.data)
        return response.data
      }
    } catch (e) {
      return e
    }
  },
  async updateNotificationStatus({ commit }, payload) {
    try {
      const response = await createOrUpdate({ path: `v1/notifications/${payload.id}`, data: {status: payload.status}, method: "patch"})
      if (response.status === 200) {
        commit("removeUnreadNotifications", payload.id)
        return response.data
      }
    } catch (e) {
      return e
    }
  },
}

const getters = {
  allNotifications (state) {
    return state.notifications
  },
  unreadNotifications (state) {
    return state.unreadNotifications
  },
  notificationCount (state) {
    return state.unreadNotifications.length
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}