import EventBus from '@/utils/event-bus'
import { createOrUpdate, _get } from '@/utils/api-request'

const state = {
  auth: localStorage.getItem('accessToken') || false,
  accessToken: localStorage.getItem('accessToken') || undefined,
  refreshToken: localStorage.getItem('refreshToken') || undefined,
  userInfo: {}
}

const mutations = {
  updateLoginStatus(state, payload) {
    state.auth = payload
  },
  setUserInfo(state, userData) {
    state.userInfo = userData
  },
  updateUsername(state, userData) {
    state.userInfo.username = userData.username
  },
  updateAccessToken(state, token) {
    state.accessToken = token
  },
  updateRefreshToken(state, token) {
    state.refreshToken = token
  },
  logout(state) {
    state.auth = false
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

const actions = {
  updateAccessToken({ commit }, payload) {
    localStorage.setItem('accessToken', payload.auth_token)
    localStorage.setItem('refreshToken', payload.refresh_token)
    commit('updateAccessToken', payload.auth_token)
    commit('updateRefreshToken', payload.refresh_token)
    commit('updateLoginStatus', true)
  },
  async getLoginUserInfo({ commit }) {
    try {
      const response = await _get({ path: "v1/me" })
      if (response.status === 200) {
        commit('setUserInfo', response.data.data)
        return response
      }
    } catch (e) {
      return e
    }
  },
  async refreshTokenAction({ state, commit }) {
    try {
      const _payload = {
        refresh_token: state.refreshToken
      }
      const response = await createOrUpdate({ path: "refresh-token", data: _payload })
      localStorage.setItem('accessToken', response.data.data.auth_token)
      localStorage.setItem('refreshToken', response.data.data.refresh_token)
      commit('updateAccessToken', response.data.data.auth_token)
      commit('updateRefreshToken', response.data.data.refresh_token)
      return response
    } catch (e) {
      return e.response
    }
  },
  async updateUserInfomationAction({ commit }, payload) {
    try {
      // API call first and then update to local
      const response = await createOrUpdate(
        {
          path: `v1/users/${payload.userEmail}`,
          data: payload.data,
          method: "put"
        }
      )
      if (response && response.data.status === 200) {
        commit("setUserInfo", payload.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async updateUsernameAction({ commit }, payload) {
    try {
      // API call first and then update to local
      const response = await createOrUpdate(
        {
          path: `v1/users/${payload.userEmail}`,
          data: payload.data,
          method: "patch"
        }
      )
      if (response && response.data.status === 200) {
        commit("updateUsername", payload.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async userPasswordUpdate({  }, payload) {
    try {
      // API call first and then update to local
      const response = await createOrUpdate(
        {
          path: `v1/me/password-reset`,
          data: payload.data,
          method: "post"
        }
      )
      return response.data
    } catch (e) {
      return e.response
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async forgotPassword({  }, email) {
    try {
      // API call first and then update to local
      const response = await createOrUpdate(
        {
          path: `v1/users/forget-password/${email}`,
          data: {},
          method: "post"
        }
      )
      return response.data
    } catch (e) {
      return e.response
    }
  },
  triggerLogout({ commit }) {
    commit('logout')
    EventBus.$emit('TRIGGERED_LOGOUT')
  }
}

const getters = {
  isAuthenticated(state) {
    return state.auth
  },
  getAccessToken(state) {
    return state.accessToken
  },
  loginUserInfo(state) {
    return state.userInfo
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}