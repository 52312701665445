import { createOrUpdate, _del, _get } from "@/utils/api-request";



const state = {
  wallets: [],
  walletPagination: {},
  categories: [],
  flatCategories: [],
  categoryPagination: {},
  transactions: [],
  transactionPagination: {},
}

const mutations = {
  addNewWallet(state, wallet) {
    state.wallets.push(wallet)
  },
  updateExistingWallet(state, wallet) {
    const _index = state.wallets.findIndex((item) => {
      return item.id === wallet.id
    })
    if (_index !== -1) {
      state.wallets.splice(_index, 1, wallet)
    }
  },
  updateWalletBalance(state, payload) {
    const _index = state.wallets.findIndex((item) => {
      return item.id === payload.walletId
    })
    if (_index !== -1) {
      let _wallet = state.wallets[_index]
      _wallet.current_amount += JSON.parse(payload.amount)
      state.wallets.splice(_index, 1, _wallet)
    }
  },
  removeWallet(state, walletId) {
    const _index = state.wallets.findIndex((item) => {
      return item.id === walletId
    })
    if (_index !== -1) {
      state.wallets.splice(_index, 1)
    }
  },
  appendWallets(state, walletResponse) {
    state.wallets.push(walletResponse.data)
    state.walletPagination = walletResponse.pagination
  },
  addNewCategory(state, category) {
    if (category.parent && category.parent.id) {
      const parentIndex = state.categories.findIndex((item) => {
        return item.id === category.parent.id
      })
      if (parentIndex !== -1) {
        state.categories[parentIndex].children.push(category)
      } else {
        console.log("No parent found")
        state.categories.push(category)
      }
    } else {
      state.categories.push(category)
    }
    state.flatCategories.push(category)
  },
  updateExistingCategory(state, category) {
    // Place the category to right place
    if (category.parent && category.parent.id) {
      const parentIndex = state.categories.findIndex((item) => {
        return item.id === category.parent.id
      })
      if (parentIndex !== -1) {
        const catIndex = state.categories[parentIndex].children.findIndex(i => i.id === category.id)
        if (catIndex !== -1) {
          state.categories[parentIndex].children.splice(catIndex, 1, category)
        } else {
          // Append to the list when it's newly moved
          state.categories[parentIndex].children.push(category)
        }
      } else {
        state.categories.push(category)
      }
    } else {
      // If there is parent then there must be child
      const catIndex = state.categories.findIndex((item) => {
        return item.id === category.id
      })
      if (catIndex && catIndex !== -1) {
        const oldData = state.categories[catIndex]
        state.categories.splice(catIndex, 1, { ...category, children: oldData.children })
      }
    }

    // find old parent id
    const _index = state.flatCategories.findIndex((item) => {
      return item.id === category.id
    })
    if (_index && _index !== -1) {
      if (state.flatCategories[_index].parent && state.flatCategories[_index].parent.id) {
        const oldParentId = _index !== -1 ? state.flatCategories[_index].parent.id : null
        if (oldParentId) {
          const oldParentIndex = state.categories.findIndex(i => i.id === oldParentId)
          if (oldParentIndex !== -1) {
            const catInIndex = state.categories[oldParentIndex].children.findIndex(i => i.id == category.id)
            if (catInIndex) {
              state.categories[oldParentIndex].children.splice(catInIndex, 1)
            }
          }
        }
      }
    }

    // Update flat categories
    if (_index !== -1) {
      state.flatCategories.splice(_index, 1, category)
    }
  },
  removeCategory(state, categoryId) {
    const _index = state.categories.findIndex((item) => {
      return item.id === categoryId
    })
    if (_index !== -1) {
      state.categories.splice(_index, 1)
    }
  },
  setWallets(state, walletResponse) {
    state.wallets = walletResponse.data
    state.walletPagination = walletResponse.pagination
  },
  appendCategories(state, categoryResponse) {
    state.categories.push(categoryResponse.data)
    state.categoryPagination = categoryResponse.pagination
  },
  setCategories(state, categoryResponse) {
    state.categories = categoryResponse.data
    const categories = JSON.parse(JSON.stringify(state.categories))
    let new_categories = []
    for (let i = 0; i < categories.length; i++) {
      const item = categories[i]
      new_categories.push(item)
      if (item.children) {
        new_categories.push(...item.children)
      }
    }
    state.flatCategories = new_categories
    state.categoryPagination = categoryResponse.pagination
  },
  addNewTransaction(state, transaction) {
    state.transactions.unshift(transaction)
  },
  setTransactions(state, response) {
    state.transactions = response.data
    state.transactionPagination = response.pagination
  },
  appendTransactions(state, response) {
    state.transactions = [...state.transactions, ...response.data]
    state.transactionPagination = response.pagination
  },
}

const actions = {
  async getAllWalletsFromAPI({ commit }) {
    try {
      const path = "v1/money/wallets"
      const response = await _get({ path: path })
      if (response.status === 200) {
        commit('setWallets', response.data)
        return response
      }
    } catch (e) {
      return e
    }
  },
  async getAllCategoriesFromAPI({ commit }) {
    try {
      const path = "v1/money/categories"
      const response = await _get({ path: path })
      if (response.status === 200) {
        commit('setCategories', response.data)
        return response
      }
    } catch (e) {
      return e
    }
  },
  async getSingleTransactionFromAPI({ commit }, transactionId) {
    try {
      const response = await _get({ path: `v1/money/transactions/${transactionId}` })
      if (response.status === 200) {
        commit('addNewTransaction', response.data.data)
        return response.data
      }
    } catch (e) {
      return e.response.data
    }
  },
  async reverseSingleTransactionFromAPI({ commit }, transactionId) {
    try {
      const response = await createOrUpdate({ path: `v1/money/transactions/${transactionId}`, data: {} })
      if (response.status === 201) {
        commit('addNewTransaction', response.data.data)
        return response.data
      }
    } catch (e) {
      return e.response.data
    }
  },
  async getAllTransactionsFromAPI({ state, commit }, payload) {
    try {
      let filterParams = ''
      let path = 'v1/money/transactions'
      if (payload && payload.filters) {
        Object.keys(payload.filters).map((f) => {
          if (payload.filters[f]) {
            filterParams += `&${f}=${payload.filters[f]}`
          }
        })
        path = `v1/money/transactions?${filterParams}`
      } else if (payload && payload.noNext) {
        path = `v1/money/transactions`
      } else {
        path = state.transactionPagination.next ? `v1/money/transactions${state.transactionPagination.next}${filterParams}` : `v1/money/transactions?${filterParams}`
      }

      const response = await _get({ path: path })
      if (response.status === 200) {
        if (payload && payload.search) {
          commit('setTransactions', response.data)
        } else {
          if (state.transactionPagination.next && !payload.noAppend) {
            commit('appendTransactions', response.data)
          } else {
            commit('setTransactions', response.data)
          }
        }
        return response
      }
    } catch (e) {
      return e
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async exportTransactions({ }, payload) {
    try {
      let filterParams = ''
      let path = 'v1/money/transactions/export'
      if (payload && payload.filters) {
        Object.keys(payload.filters).map((f) => {
          if (payload.filters[f]) {
            filterParams += `&${f}=${payload.filters[f]}`
          }
        })
      }
      path = `v1/money/transactions/export?${filterParams}`

      const response = await _get({ path: path })
      if (response.status === 200) {
        return response
      }
    } catch (e) {
      return e
    }
  },
  async createOrUpdateWalletAction({ commit }, payload) {
    try {
      const path = payload.id ? `v1/money/wallets/${payload.id}` : 'v1/money/wallets'
      const method = payload.id ? 'put' : 'post'
      const response = await createOrUpdate(
        {
          path: path,
          data: payload,
          method: method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewWallet", response.data.data)
      } else if (response && response.data.status === 200) {
        commit("updateExistingWallet", response.data.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async createOrUpdateTransactionAction({ commit }, payload) {
    try {
      const path = payload.id ? `v1/money/transactions/${payload.id}` : 'v1/money/transactions'
      const method = payload.id ? 'put' : 'post'
      const response = await createOrUpdate(
        {
          path: path,
          data: payload,
          method: method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewTransaction", response.data.data)
        if (payload.trx_type != "transfer") {
          commit("updateWalletBalance", {walletId: payload.wallet_id, amount: payload.trx_type == "expense" ? -payload.amount : payload.amount})
        } else {
          commit("updateWalletBalance", {walletId: payload.wallet_id, amount: -payload.amount})
          commit("updateWalletBalance", {walletId: payload.to_wallet_id, amount: payload.amount})
        }
      } else if (response && response.data.status === 200) {
        commit("updateExistingTransaction", response.data.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async deleteWalletAction({ commit }, payload) {
    try {
      const response = await createOrUpdate({ path: `v1/money/wallets/${payload.walletId}`, method: "patch", data: { status: payload.status } })
      if (response && response.status === 200) {
        commit("updateExistingWallet", response.data.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async adjustWalletBalanceAction({ commit }, payload) {
    try {
      const response = await createOrUpdate({ path: `v1/money/wallets/${payload.id}/adjust-amount`, data: payload, method: 'put' })
      if (response && response.status === 200) {
        commit("updateExistingWallet", payload)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async createOrUpdateCategoryAction({ commit }, payload) {
    try {
      const path = payload.id ? `v1/money/categories/${payload.id}` : 'v1/money/categories'
      const method = payload.id ? 'put' : 'post'
      const response = await createOrUpdate(
        {
          path: path,
          data: payload,
          method: method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewCategory", response.data.data)
      } else if (response && response.data.status === 200) {
        commit("updateExistingCategory", response.data.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async deleteCategoryAction({ commit }, categoryId) {
    try {
      const response = await _del({ path: `v1/money/categories/${categoryId}` })
      if (response && response.status === 206) {
        commit("removeCategory", categoryId)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
}

const getters = {
  wallets(state) {
    return state.wallets
  },
  activeWallets(state) {
    return state.wallets.filter(item => item.status == 1)
  },
  categories(state) {
    return state.categories
  },
  flatCategories(state) {
    return state.flatCategories
  },
  flatCategoriesWithoutParent(state) {
    return state.flatCategories.filter((item) => {
      if (item.parent === null && item.children.length === 0) {
        return item
      }
      if (item.parent !== null) {
        return item
      }
    })
  },
  transactions(state) {
    return state.transactions
  },
  transactionPagination(state) {
    return state.transactionPagination
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}