import { createOrUpdate } from "../../utils/api-request";


const state = {
}

const mutations = {
}

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async uploadImage({ }, image) {
    try {
      let formData = new FormData();
      formData.append("file", image)
      const fileUploadResponse = await createOrUpdate(
        {
          path: "v1/image-uploads",
          data: formData,
          _headers: { "Content-Type": "multipart/form-data" }
        }
      )
      if (fileUploadResponse && fileUploadResponse.status === 200) {
        return fileUploadResponse.data
      } else {
        return fileUploadResponse.data
      }

    } catch (e) {
      return e.response.data
    }
  }
}

const getters = {
}

export default {
  state,
  mutations,
  getters,
  actions
}