import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import middlewares from '../middlewares/middlewares'

Vue.use(VueRouter)

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    }
  },
  {
    path: '/notes',
    name: 'NoteListView',
    component: () => import(/* webpackChunkName: "note-wrapper-view" */'../views/keep/NoteWrapperView.vue'),
    meta: {
      title: "List of notes | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    },
    children: [
      {
        path: 'all',
        component: () => import(/* webpackChunkName: "note-list-view" */'../views/keep/NoteListView.vue'),
        meta: {
          title: "List of notes | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: 'archive',
        component: () => import(/* webpackChunkName: "archive-note-list-view" */'../views/keep/ArchiveNoteListView.vue'),
        meta: {
          title: "List of archive notes | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: 'trash',
        component: () => import(/* webpackChunkName: "deleted-note-list-view" */'../views/keep/DeletedNoteListView.vue'),
        meta: {
          title: "List of deleted notes | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: 'labels',
        component: () => import(/* webpackChunkName: "all-label-note-list-view" */'../views/keep/AllLabelNoteListView.vue'),
        meta: {
          title: "List of notes under all labels | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: ':labelId',
        component: () => import(/* webpackChunkName: "label-note-list-view" */'../views/keep/LabelNoteListView.vue'),
        meta: {
          title: "List of notes under a label | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      }
    ]
  },
  {
    path: '/money',
    name: 'MoneyManagerWrapperView',
    component: () => import(/* webpackChunkName: "money-wrapper-view" */'../views/money/MoneyManagerWrapperView.vue'),
    meta: {
      title: "Money Manager | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    },
    children: [
      {
        path: 'wallets',
        component: () => import(/* webpackChunkName: "wallet-list-view" */'../views/money/WalletListView.vue'),
        meta: {
          title: "List of Wallets | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: 'categories',
        component: () => import(/* webpackChunkName: "category-list-view" */'../views/money/CategoryListView.vue'),
        meta: {
          title: "List of Categories | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
      {
        path: 'transactions',
        component: () => import(/* webpackChunkName: "transaction-list-view" */'../views/money/TransactionListView.vue'),
        meta: {
          title: "List of Transactions | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
    ]
  },
  {
    path: '/reminders',
    component: () => import(/* webpackChunkName: "reminder-wrapper-view" */'../views/reminder/ReminderWrapperView.vue'),
    meta: {
      title: "Reminder | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "reminder-list-view" */'../views/reminder/ReminderListView.vue'),
        meta: {
          title: "Reminders | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
    ]
  },
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "reports-wrapper-view" */'../views/reports/ReportWrapperView.vue'),
    meta: {
      title: "Reports | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    },
    children: [
      {
        path: 'transactions',
        component: () => import(/* webpackChunkName: "transaction-report-view" */'../views/reports/TransactionReportView.vue'),
        meta: {
          title: "Transaction Report | AmarDiary",
          middleware: [
            middlewares.isAuthenticated
          ]
        }
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: "About me | AmarDiary",
    }
  },
  {
    path: '/me',
    name: 'LoginUserLandingView',
    component: () => import(/* webpackChunkName: "login-user-landing-view" */ '../views/LoginUserLandingView.vue'),
    meta: {
      title: "User Profile | AmarDiary",
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/auth/Login.vue'),
    meta: {
      title: "Login | AmarDiary",
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../components/auth/Signup.vue'),
    meta: {
      title: "Signup | AmarDiary",
    }
  },
  {
    path: '/verify-signup',
    name: 'VerifySignup',
    component: () => import(/* webpackChunkName: "verify-signup" */ '../components/auth/VerifySignup.vue'),
    meta: {
      title: "Verify Signup | AmarDiary",
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "forget-password" */ '../components/auth/ForgetPassword.vue'),
    meta: {
      title: "Forget Password | AmarDiary",
    }
  },
  {
    path: "/invitation-request",
    name: "RequestForInvitation",
    component: () => import(/* webpackChunkName: "request-for-invitation" */ '../views/invitation/RequestForInvitationView.vue'),
    meta: {
      title: "Request for Invitation | AmarDiary",
    }
  },
  {
    path: "/invitation/requests",
    name: "InvitationRequestListView",
    component: () => import(/* webpackChunkName: "invitation-request-list-view" */ '../views/invitation/InvitationRequestListView.vue'),
    meta: {
      title: "Invitation request | AmarDiary",
      middleware: [
        middlewares.isAuthenticated
      ]
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "404-not-found" */ '../views/NotFound.vue'),
    meta: {
      title: "404! Page not found | AmarDiary",
    }
  }
]
