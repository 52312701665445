export const API_URL = 'https://diary-api.ashraful.dev/api/'
export const BASE_IMAGE_URL = "https://diary-api.ashraful.dev/media/image"
export const FIREBASE_PUBLIC_API_KEY = "BOH6m4ip_oLfDQZcObGBzQx2Z8WZbAdDoUPa1UTMthuX2DqY8U00cph439m6KVFoF5C70hK8A2yLiVB1gtDG3Ho"

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA6sEidWkEqAbsIcyTndjw5POeIA1MWIxY",
    authDomain: "my-space-2022.firebaseapp.com",
    projectId: "my-space-2022",
    storageBucket: "my-space-2022.appspot.com",
    messagingSenderId: "254142786927",
    appId: "1:254142786927:web:31e203fbe7f686369c8c9a",
    measurementId: "G-GT24KC9LKP"
}

export const NoteTypeEnum = {
  PLAIN_TEXT: "Text",
  CHECKBOX: "Checkbox",
  MARKDOWN: "Markdown"
}

export const MAX_TRX_IMAGE_SIZE = 2 * 1000 * 1000