export default async function loadInitialData(store) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (store.getters.isAuthenticated) {
        const promiseArray = [
          store.dispatch('getLoginUserInfo'),
          store.dispatch('getAllLabelsFromAPI'),
          store.dispatch('getUnreadNotifications'),
        ]
        await Promise.all(promiseArray)
      }
    } catch (e) {
      reject(e)
    }
    resolve()
  })
}