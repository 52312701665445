<template>
  <div>
    <div class="title text-center">
      Category Wise Total Expense ({{ reportType }})
    </div>
    <Bar
      :chart-data="chartData"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: "TransactionCategoryWiseReport",
  components: { Bar },
  props: {
    labels: {
      type: Array,
      required: true
    },
    dataset: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 200
    },
    reportType: {
      type: String,
      default: "Monthly"
    }
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.dataset,
      }
    }
  }
}
</script>