import {_get, createOrUpdate, _del} from "../../utils/api-request"

const state = {
  reminders: [],
  reminderPaginationInfo: {}
}

const mutations = {
  addNewReminder(state, reminder) {
    state.reminders.push(reminder)
  },
  setReminders(state, reminders) {
    state.reminders = reminders.data
    state.reminderPaginationInfo = reminders.pagination || {}
  },
  updateExistingReminder(state, reminder) {
    const _index = state.reminders.findIndex((item) => {
      return item.id === reminder.id
    })
    if (_index !== -1) {
      state.reminders.splice(_index, 1, reminder)
    }
  },
  removeReminder(state, reminderId) {
    const _index = state.reminders.findIndex((item) => {
      return item.id === reminderId
    })
    if (_index !== -1) {
      state.reminders.splice(_index, 1)
    }
  },
  appendReminders(state, reminderResponse) {
    state.reminders.push(reminderResponse.data)
    state.reminderPaginationInfo = reminderResponse.pagination
  },
}

const actions  = {
  async getAllRemindersFromAPI({ commit }) {
    try {
      const path = "v1/reminder/schedules"
      const response = await _get({ path: path })
      if (response.status === 200) {
        commit('setReminders', response.data)
        return response
      }
    } catch (e) {
      return e
    }
  },
  async createOrUpdateReminderAction({ commit }, payload) {
    try {
      const path = payload.id ? `v1/reminder/schedules/${payload.id}` : 'v1/reminder/schedules'
      const method = payload.id ? 'put' : 'post'
      const response = await createOrUpdate(
        {
          path: path,
          data: payload,
          method: method
        }
      )
      if (response && response.data.status === 201) {
        commit("addNewReminder", response.data.data)
      } else if (response && response.data.status === 200) {
        commit("updateExistingReminder", response.data.data)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async deleteReminderAction({ commit }, reminderId) {
    try {
      const response = await _del({ path: `v1/reminder/schedules/${reminderId}` })
      if (response && response.status === 206) {
        commit("removeReminder", reminderId)
      }
      return response.data
    } catch (e) {
      return e.response
    }
  },
}


const getters = {
  allReminders(state) {
    return state.reminders
  }
}


export default {
  state,
  mutations,
  actions,
  getters
}