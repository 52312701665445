import Axios from 'axios'
import store from '../store'
import { API_URL } from './constants'

function makeApiUrl(path) {
  return `${API_URL}${path}`
}

function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function _triggerEmergencyLogout() {
  store.dispatch('triggerLogout')
}

async function callRefreshToken() {
  const response = await store.dispatch('refreshTokenAction')
  if (response.status === 200) {
    return 200
  } else {
    return 479
  }
}

export const _get = async function ({ path, noToken = false, _headers = {}, responseType = "json" }) {
  _headers["X-TIME-ZONE"] = getTimezone()
  if (!noToken) {
    _headers.Authorization = `Bearer ${store.getters.getAccessToken}`
  }
  const url = makeApiUrl(path)
  const response = await Axios.get(url, { headers: _headers, responseType: responseType }).catch(async (err) => {
    if (err.response.status === 479) {
      const refreshTokenStatus = await callRefreshToken()
      if (refreshTokenStatus === 479) {
        _triggerEmergencyLogout()
      } else {
        return await _get({ path, noToken, _headers })
      }
    }
    throw err
  })
  return response
}

export const createOrUpdate = async function ({ path, data, noToken = false, method = 'post', _headers = {} }) {
  _headers["X-TIME-ZONE"] = getTimezone()
  if (!noToken) {
    _headers.Authorization = `Bearer ${store.getters.getAccessToken}`
  }
  const url = makeApiUrl(path)
  if (method === 'put') {
    return await Axios.put(url, data, { headers: _headers }).catch(async (err) => {
      if (err.response.status === 479) {
        const refreshTokenStatus = await callRefreshToken()
        if (refreshTokenStatus === 479) {
          _triggerEmergencyLogout()
        } else {
          return await createOrUpdate({ path, data, noToken, method, _headers })
        }
      }
      throw err
    })
  } else if (method === 'patch') {
    return await Axios.patch(url, data, { headers: _headers }).catch(async (err) => {
      if (err.response.status === 479) {
        const refreshTokenStatus = await callRefreshToken()
        if (refreshTokenStatus === 479) {
          _triggerEmergencyLogout()
        } else {
          return await createOrUpdate({ path, data, noToken, method, _headers })
        }
      }
      throw err
    })
  }
  const response = await Axios.post(url, data, { headers: _headers }).catch(async (err) => {
    _headers["X-TIME-ZONE"] = getTimezone()
    if (err.response.status === 479) {
      const refreshTokenStatus = await callRefreshToken()
      if (refreshTokenStatus === 479) {
        _triggerEmergencyLogout()
      } else {
        return await createOrUpdate({ path, data, noToken, method, _headers })
      }
    }
    throw err
  })
  return response
}

export const _del = async function ({ path, noToken = false, _headers = {} }) {
  _headers["X-TIME-ZONE"] = getTimezone()
  if (!noToken) {
    _headers.Authorization = `Bearer ${store.getters.getAccessToken}`
  }
  const url = makeApiUrl(path)
  const response = await Axios.delete(url, { headers: _headers }).catch(async (err) => {
    if (err.response.status === 479) {
      const refreshTokenStatus = await callRefreshToken()
      if (refreshTokenStatus === 479) {
        _triggerEmergencyLogout()
      } else {
        return await _del({ path, noToken, _headers })
      }
    }
    throw err
  })
  return response
}