<template>
  <v-navigation-drawer
    class="nav-drawer"
    v-model="navDrawer"
    @input="handleDrawerToggleEvent"
    app
  >
    <v-list>
      <v-list-item
        to="/me"
        two-line
      >
        <v-list-item-avatar class="name-initials">
          <v-img
            v-if="loginUserDetails.profile_photo"
            :alt="makeNameInitials(loginUserDetails.name)"
            :src="`${baseImageUrl}?path=${loginUserDetails.profile_photo}`"
          />
          <span v-else>{{ makeNameInitials(loginUserDetails.name) }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ loginUserDetails.name }} </v-list-item-title>
          <v-list-item-subtitle>
            {{ loginUserDetails.role }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <div
        v-for="(item, i) in sideNavs"
        :key="i"
      >
        <v-list-item
          v-if="
            !item.subLinks && item.accessLevel.includes(loginUserDetails.role)
          "
          active-class="primary--text"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else-if="
            item.subLinks && item.accessLevel.includes(loginUserDetails.role)
          "
          :key="item.title"
          :no-action="true"
          :prepend-icon="item.icon"
          active-class="greyblack--text"
          :value="$route.path.includes(item.to)"
        >
          <template #activator>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="subItem in item.subLinks"
            :key="subItem.to"
            active-class="primary--text"
            :to="`${item.to + subItem.to}`"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { BASE_IMAGE_URL } from "@/utils/constants";

export default {
  name: "NavigationDrawer",
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navDrawer: true,
      baseImageUrl: BASE_IMAGE_URL,
      subLinkPaths: ["/notes", "/money", "/invitation",],
      sideNavs: [
        {
          icon: "mdi-view-dashboard-variant",
          title: "Dashboard",
          identifier: "dashboard",
          to: "/",
          dropdown: false,
          accessLevel: ["Member", "Admin", "SuperAdmin"],
        },
        {
          icon: "mdi-notebook-outline",
          title: "Notes",
          identifier: "notes",
          to: "/notes",
          dropdown: false,
          accessLevel: ["Member", "Admin", "SuperAdmin"],
          subLinks: [
            {
              icon: "mdi-lightbulb-on",
              title: "Active",
              identifier: "all_notes",
              to: "/all",
            },
            {
              icon: "mdi-delete",
              title: "Trash",
              identifier: "deleted",
              to: "/trash",
            },
            {
              icon: "mdi-package-down",
              title: "Archive",
              identifier: "archived",
              to: "/archive",
            },
          ],
        },
        {
          icon: "mdi-bell-ring-outline",
          title: "Reminder",
          identifier: "reminder",
          to: "/reminders",
          dropdown: false,
          accessLevel: ["SuperAdmin"],
        },
        {
          icon: "mdi-cash-multiple",
          title: "Money",
          identifier: "money",
          to: "/money",
          dropdown: false,
          accessLevel: ["Member", "Admin", "SuperAdmin"],
          subLinks: [
            {
              icon: "mdi-wallet-outline",
              title: "Wallets",
              identifier: "all_wallets",
              to: "/wallets",
            },
            {
              icon: "mdi-shape",
              title: "Categories",
              identifier: "all_categories",
              to: "/categories",
            },
            {
              icon: "mdi-cash-multiple",
              title: "Transactions",
              identifier: "all_transactions",
              to: "/transactions",
            },
          ],
        },
        {
          icon: "mdi-chart-bar",
          title: "Reports",
          identifier: "reports",
          to: "/reports",
          dropdown: true,
          accessLevel: ["Member", "Admin", "SuperAdmin"],
          subLinks: [
            {
              icon: "mdi-finance",
              title: "Transaction",
              identifier: "reports_transaction",
              to: "/transactions",
            },
          ],
        },
        {
          icon: "mdi-email-fast",
          title: "Invitation",
          identifier: "invitation",
          to: "/invitation",
          dropdown: true,
          accessLevel: ["SuperAdmin"],
          subLinks: [
            {
              icon: "mdi-email-arrow-left",
              title: "Requests",
              identifier: "invitation_requests",
              to: "/requests",
            },
          ],
        },
        {
          icon: "mdi-tools",
          title: "Settings",
          identifier: "settings",
          to: "/settings",
          dropdown: false,
          accessLevel: ["SuperAdmin"],
        },
      ],
    };
  },
  computed: {
    loginUserDetails() {
      return this.$store.getters.loginUserInfo || {};
    },
    allLabelList() {
      return this.$store.getters.allLabelList;
    },
    labelNavs() {
      return this.$store.getters.labelsNavItems;
    },
  },
  methods: {
    handleDrawerToggleEvent(value) {
      this.navDrawer = value;
      this.$emit("onUpdateDrawer", value);
    },
    makeNameInitials(name) {
      if (!name) {
        return "";
      }
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
  },
  watch: {
    drawer(newValue) {
      this.navDrawer = newValue;
    },
    labelNavs: {
      handler(newValue) {
        if (newValue) {
          const noteNavItem = this.sideNavs[1]
          const noteSubLinksCopied = JSON.parse(JSON.stringify(noteNavItem.subLinks.slice(0, 3)))
          noteNavItem.subLinks = newValue.subLinks
          noteNavItem.subLinks.unshift(...noteSubLinksCopied)
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.name-initials {
  border: 1px solid;
  border-radius: 50%;
}
</style>
